import axios from 'axios'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { TiDeleteOutline } from 'react-icons/ti'
import '../App.css'

const FollowersWindow = styled.div`
    display: flex;
    background: #0000009e;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: +3;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 30rem;
    margin: 10px;
    border-radius: 10px;
    font-family: Manrope;
    overflow: hidden;
`

const ClosePanel = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
`
const H2 = styled.h2`
    margin: 0;
    background: linear-gradient(148deg, #ff5b05, #ffa411);
    color: white;
    padding: 16px;
`
const Follower = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin: 20px;
     position: relative;
`
const User = styled.span`
     display: flex;
     align-items: center;
     font-size: 12px;
`
const Date = styled.span`
     font-size: 9px;
     color: gray;
     margin: 5px 14px;
`
const RemoveSubscriber = styled.span`
     display: none;
     position: absolute;
     right: 0;
     cursor: pointer;
  }
`

const ConrfirmRemove = styled.div`
background: white;
margin: 0 auto;
border-radius: 4px;
bottom: 2%;
width: 20rem;
padding: 10px;
align-items: center;
display: flex;
justify-content: center;
`

const Button = styled.button`
margin: 0 8px;
background: none;
width: 3rem;
border: none;
padding: 4px;
border-radius: 2px;
cursor: pointer;
`


function Followers(props) {
    const sCode = sessionStorage.getItem('security_code')
    const [followers, setFollowers] = useState([])
    const [followerEmail, setFollowerEmail] = useState('')
    const [removeFollower, setRemoveFollower] = useState(false);
    const [refresh, setRefresh] = useState(Math.random())

    useEffect(() => {
        const formdata = new FormData();
        formdata.append('sCode', sCode)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_GET_FOLLOWERS, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const obj = JSON.parse(xhr.responseText)
            console.log(obj)
            if (xhr.status == 200) {
                setFollowers(obj.result)
            } else {
                alert(obj.result)
            }
        }
        xhr.send(formdata)
    }, [refresh])

    const confirm_remove = () => {
        axios.post(process.env.REACT_APP_API_URL + '/remove_follower', {
            email: followerEmail,
        }).then(res => {
            setRemoveFollower(false)
            alert(res.data.result)
            setRefresh(Math.random())
        }).catch(err => {
            alert(err.response.data.result)
        })
    }

    return (
        <FollowersWindow>
            <ClosePanel onClick={() => props.FollowersWindow(false)}></ClosePanel>
            <Container>
                <H2>Followers</H2>
                <div>
                    {followers && followers.map(val => (
                        <Follower key={val._id} className='follower'>
                            <User><AiOutlineUserAdd color='gray' />&nbsp;{val.Email}</User>
                            <Date>{val.Date}</Date>
                            <RemoveSubscriber className='removeFollower' onClick={() => setRemoveFollower(true) +
                                setFollowerEmail(val.Email)}>
                                <TiDeleteOutline size={20} color='red' />
                            </RemoveSubscriber>
                        </Follower>
                    ))}
                </div>
                {removeFollower &&
                    <ConrfirmRemove>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{
                                margin: '0 16px',
                                fontWeight: 600,
                                color: '#382716',
                            }}>Confirm remove ?</span>
                            <Button style={{ border: '1px solid #fe7d02' }} onClick={() => confirm_remove()}>
                                Yes
                            </Button>
                            <Button style={{ background: '#fe7d02', color: 'white' }}>
                                No
                            </Button>
                        </div>
                    </ConrfirmRemove>
                }
            </Container>

        </FollowersWindow >
    )
}

export default Followers;