import styled from 'styled-components'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { MdRemoveCircleOutline } from 'react-icons/md'
import cartPng from '../Images/cart.png'
import { ScaleLoader } from 'react-spinners'

const ImagesWindow = styled.div`
   display: flex;
   background: #0000009e;
   justify-content: center;
   align-items: center;
   position: fixed;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: +4;
`
const ImagesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: whitesmoke;
    width: 830px;
    border-radius: 16px;
    height: 38rem;
    margin: 16px;
    font-family: Manrope;
    overflow: hidden;
    position: relative;
`
const H2 = styled.h2`
display: flex;
align-items: center;
justify-content: center;
background: darkorange;
color: white;
width: -webkit-fill-available;
text-align: center;
margin: 0;
padding: 8px;
z-index: +1;
`


const AllImages = styled.div`
isplay: flex;
flex-direction: column;
width: 95%;
margin-top: 2rem;
height: 75%;
overflow: hidden;
overflow-y: auto;
scroll-behavior: smooth;
`

const ImageHolder = styled.div`
    display: flex;
    position: relative;
    width: 232px;
    height: 145px;
    border: 1px solid lightgray;
    margin: 8px;
    border-radius: 4px;
    overflow: hidden;
`
const Img = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
`

const UploadImages = styled.div`
    background: #ffcb8c;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: +1;
`
const Input = styled.input`

`
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FE7D02;
    color: white;
    border-radius: 4px;
    border: none;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 5rem;
`

const ImagesWithDate = styled.div`
    display: flex;
    flex-direction: column;
`
const Date = styled.span`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 8px;
`

const ClosePanel = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
`
const Loading = styled.div`
    display: flex;
    background: #00000059;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: +1;
    justify-content: center;
    align-items: center;

`

function Images(props) {
    const sCode = sessionStorage.getItem('security_code')
    const [picture, setPicture] = useState()
    const [picData, setPicData] = useState([])
    const [refresh, setRefresh] = useState(Math.random())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const formdata = new FormData();
        formdata.append('sCode', sCode)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_GET_IMAGES, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const obj = JSON.parse(xhr.responseText)
            if (xhr.status == 200) {
                setPicData(obj.result)
            } else {
                console.log(obj.result)
            }
        }
        xhr.send(formdata);

    }, [refresh])



    // upload pictures 
    const uploadPictures = () => {
        const formdata = new FormData();
        formdata.append('sCode', sCode)
        formdata.append('image', picture)
        setLoading(true)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_UPLOAD_IMAGES, true);
        xhr.getResponseHeader('Content-type', 'multipart/form-data');
        xhr.onload = () => {
            const obj = JSON.parse(xhr.responseText)
            console.log(obj)
            if (xhr.status == 200) {
                alert(obj.result)
                setLoading(false)
                setRefresh(Math.random())
                document.querySelector('#fileInp').value = '';
            } else {
                setLoading(false)
                if (xhr.status === 500) {
                    alert('Please select image file.')
                } else {
                    alert(obj.result)
                }
            }
        }
        xhr.send(formdata);

    }

    const removeImg = (entryId, picLink, deletehash) => {
        setLoading(true)

        const formdata = new FormData();
        formdata.append('sCode', sCode);
        formdata.append('entryId', entryId);
        formdata.append('picLink', picLink);
        formdata.append('deletehash', deletehash);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_DELETE_IMAGES, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const obj = JSON.parse(xhr.responseText);
            if (xhr.status == 200) {
                alert(obj.result)
                setLoading(false)
                setRefresh(Math.random())
            } else {
                setLoading(false)
                console.log(obj.result)
            }
        }
        xhr.send(formdata);

    }

    return (
        <ImagesWindow>
            <ClosePanel onClick={() => props.ImageWindow(false)}></ClosePanel>
            <ImagesContainer>
                {loading &&
                    <Loading>
                        <ScaleLoader color='white' />
                    </Loading>
                }
                <H2> <img src={cartPng} style={{ width: '2rem' }} /> &nbsp; Image store</H2>
                <AllImages>
                    {picData && picData.map(val => (
                        <ImagesWithDate key={val._id}>
                            <Date>{val.Date}</Date>
                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                {val.Image && val.Image.map(val2 => (
                                    <ImageHolder key={Math.random() * 1000} className='imageHolder'>
                                        <Img src={val2.link} />
                                        <MdRemoveCircleOutline className='removePic' size={18} color='white' onClick={() => removeImg(val._id, val2.link, val2.deletehash)}
                                            style={{
                                                color: 'white',
                                                position: 'absolute',
                                                right: '14px',
                                                bottom: '10px',
                                                background: '#0000007d',
                                                padding: '4px',
                                                borderRadius: '3rem',
                                                cursor: 'pointer',
                                            }} />
                                    </ImageHolder>
                                ))}
                            </div>
                        </ImagesWithDate>
                    ))}
                </AllImages>
                <UploadImages>
                    <Input id='fileInp' type='file' onChange={(e) => setPicture(e.target.files[0])} />
                    <Button onClick={() => uploadPictures()}>Upload</Button>
                </UploadImages>
            </ImagesContainer>
        </ImagesWindow>
    )
}

export default Images;