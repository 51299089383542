import { useEffect, useState } from "react"
import axios from "axios";
import styled from "styled-components";
import NavMenu from "./NavMenu";


const StaticsPanel = styled.div`
   font-family: Manrope;
   max-width: 1200px;
   margin: 4rem 2rem;
   padding: 12px;
`
const H1 = styled.h1`
   font-family: Manrope;
   color: #46382b;
`
const Visit = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;
`
const Span = styled.span`
   display: flex;
   align-items: center;
   font-size: 14px;
   color: #46382b;
   margin: 4px 0;
`


export default function Statics() {
    const [visiData, setVisitData] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get(process.env.REACT_APP_API_URL + '/siteVisits')
            .then(res => {
                setLoading(false)
                setVisitData(res.data.result);
            })
    }, [])

    return (
        <>
            <NavMenu></NavMenu>
            <StaticsPanel>
                <H1>Statics</H1>
                {visiData && visiData.toReversed().map(val => (
                    <Visit key={val._id}>
                        <Span><strong>City:</strong>&nbsp;{val.City}</Span>
                        <Span><strong>Country:</strong>&nbsp;{val.Country}</Span>
                        <Span><strong>State:</strong>&nbsp;{val.State}</Span>
                        <Span><strong>Date:</strong>&nbsp;{val.Date}</Span>
                        <Span><strong>Time:</strong>&nbsp;{val.Time}</Span>
                        {val.DiviceType &&
                            <Span><strong>Divice:</strong>&nbsp;
                                {val.DiviceType == 'portrait-primary' ? <>Mobile</> : <>Tab/Desktop</>}
                            </Span>
                        }
                    </Visit>
                ))}
                {loading && <Span><strong>Loading...</strong></Span>}
            </StaticsPanel>
        </>
    )
}
// portrait-primary
// landscape-primary