import styled from 'styled-components'
import { Routes, Route, NavLink } from "react-router-dom";
import './App.css';
//
import Publish from './Components/Publish';
import Login from './Components/Login';
import BlogList from './Components/BlogList';
import Statics from './Components/statics';
import Messages from './Components/Messages';

const SolidPlate = styled.div`
   width: 100%;
   height: 4px;
   background: #FE7D02;
   border: none;
`

const Footer = styled.footer`
   display: flex;
   padding: 2rem;
   justify-content: center;
   align-items: center;
   width: -webkit-fill-available;
   font-family: 'Manrope';
   font-size: 12px;
`


function App() {
  return (
    <>
      <SolidPlate></SolidPlate>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route path='publish' element={<Publish />} />
        <Route path='blog_list' element={<BlogList />} />
        <Route path='statics' element={<Statics />} />
        <Route path='messages' element={<Messages />} />
      </Routes>
      <Footer>© Copyright 2023 smdevbox.com/admin</Footer>
    </>
  );
}

export default App;
