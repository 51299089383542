import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import { ScaleLoader } from 'react-spinners';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { Editor } from '@tinymce/tinymce-react'
import NavMenu from './NavMenu';
import '../App.css'
//
import previewImg from '../Images/preview.png'
import notePng from '../Images/notes.png'


const CreateBlog = styled.div`
   display: flex;
   flex-direction: column;
   font-family: 'Manrope',sans-serif;
   padding: 16px;
   max-width: fit-content;
   margin: 0 auto;
`
const H = styled.h2`
   display: flex;
   align-items: center;
   font-size: 32px;
   margin: 3rem;

   @media (max-width: 700px){
     font-size: 20px
  }
   @media (max-width: 500px){
     font-size: 18px
  }
`
const NotePng = styled.img`
   width: 2rem;

   @media (max-width: 700px){
     width: 1.5rem;
  }
   @media (max-width: 500px){
     width: 1rem;
  }
`
const ImageAndDetails = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 32px;

   @media (max-width: 800px){
       flex-direction: column;
       align-items: start;
   }
`

const ThumbnailSection = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   box-shadow: 0 0 5px lightgray;
   padding: 24px;
   border-radius: 8px;
`
const InputFile = styled.input`
   display: flex;
   margin-top: 24px;
`

const Thumbnail = styled.div`
   display: flex;
   width: 256px;
   height: 160px;
   border-radius: 4px;
   overflow: hidden;
`
const Inputs = styled.div`
   display: flex;
   flex-direction : column;
   margin: 0 32px;
   max-width: 24rem;

   @media (max-width: 800px){
        margin: 0 0;
        margin-top: 2rem;
  }
`
const Input = styled.input`
   display: flex;
   border: none;
   margin-bottom: 16px;
   padding: 5px 2px;
   font-size: 14px;
   letter-spacing: 0.4px;
   font-weight: 400;
   font-family: manrope;
   border: none;
   border-bottom: 1px solid lightgray;
   outline: none;
   width: 24rem;

   @media (max-width: 400px){
    width: 20rem;
}
`
const TagDiv = styled.div`
   display: flex;
   border: none;
   margin-bottom: 16px;
   padding: 5px 2px;
   font-size: 14px;
   letter-spacing: 0.4px;
   font-weight: 400;
   font-family: manrope;
   border: none;
   border-bottom: 1px solid lightgray;
   width: 24rem;
   flex-wrap: wrap;

   @media (max-width: 400px){
    width: 20rem;
}
`
const Select = styled.select`
   display: flex;
   border: none;
   margin-bottom: 8px;
   padding: 5px 2px;
   font-size: 14px;
   letter-spacing: 0.4px;
   font-weight: 400;
   font-family: manrope;
   border: none;
   border-bottom: 1px solid lightgray;
   outline: none;
   @media (max-width: 400px){
    width: 20rem;
 }
`
const Tag = styled.span`
    display: flex;
    align-items: center;
    background: #6e7769;
    color: white;
    padding: 2px 5px;
    font-weight: 400;
    font-family: manrope;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 14px;
    white-space: nowrap;
    margin-top: 4px;
`

const TextEditor = styled.div`
    display: flex;
    flex-direction: column;
    width: 860px;
    height: 60rem;
    margin: 0 auto;

    @media (max-width: 900px){
        width: -webkit-fill-available;
    }
`
const SubmitBtn = styled.button`
    background: #46382b;
    color: white;
    border-radius: 4px;
    border: none;
    padding: 8px;
    width: 100%;
    margin: 12px auto;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Manrope';
    letter-spacing: 0.5px;
    height: 42px;
    justify-content: center;
    align-items: center;
    position: relative;
`



function Publish() {
    const editorRef = useRef()
    const [thumbnail, setThumbnail] = useState('');
    const [editorData, setEditorData] = useState('');
    const [title, setTitle] = useState('')
    const [about, setAbout] = useState('')
    const [type, setType] = useState('')
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false)
    const sCode = sessionStorage.getItem('security_code')


    useEffect(() => {
        const formdata = new FormData();
        formdata.append('sCode', sCode)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_ME, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            if (xhr.status == 200) {

            }
            else {
                alert('Something went wrong!')
                window.location.href = '/'
            }
        }
        xhr.send(formdata)
    }, [])

    // ADDING TAGS 
    const addTag = (e) => {
        if (e.key == 'Enter') {
            if (e.target.value == '') {
                alert('Invalid tag')
            } else {
                if (tags.includes(e.target.value)) {
                    alert('Tag already exist!')
                } else {
                    setTags([...tags, e.target.value])
                    e.target.value = ''
                }
            }
        }
    }

    const removeTag = (tag) => {
        const newTags = tags.filter((tags) => {
            return tags !== tag
        })
        setTags(newTags)
    }

    // FOR Editor 
    const Plugins = [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks codesample code fullscreen',
        'insertdatetime media table paste code help wordcount emoticons'
    ]
    const Toolbar = 'undo redo | alignleft  aligncenter alignright alignjustify|' +
        'fontsizeselect | fontselect | bold italic forecolor  backcolor | formatselect |' +
        'bullist numlist outdent indent | ' +
        'removeformat |' +
        'pagebreak | charmap emoticons | fullscreen preview | ' +
        'insertfile image media template link anchor codesample code | ltr rtl' +
        'useBrowserSpellcheck'

    // Submit Blog
    const submitBlog = () => {
        setLoading(true)
        const formdata = new FormData();
        formdata.append('thumbnail', thumbnail);
        formdata.append('type', type);
        formdata.append('title', title);
        formdata.append('about', about);
        formdata.append('tags', JSON.stringify(tags));
        formdata.append('article', editorData);
        formdata.append('sCode', sCode);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_PUBLISH, true);
        xhr.getResponseHeader('Content-type', 'multipart/form-data');
        xhr.onload = () => {
            setLoading(false)
            const obj = JSON.parse(xhr.responseText)
            if (xhr.status === 200) {
                alert(obj.result)
                window.location.reload()
            } else {
                alert(obj.result)
            }
        }
        xhr.send(formdata);
    }

    return (
        <>
            <NavMenu></NavMenu>
            <CreateBlog>
                <H style={{ color: '#46382B', }}>Write a Blog Post &nbsp; <NotePng src={notePng} style={{
                    width: '40px',
                    transform: 'rotate(15deg)',
                }} /> </H>
                <ImageAndDetails>
                    <ThumbnailSection>
                        <Thumbnail>
                            {thumbnail && <img src={URL.createObjectURL(thumbnail)} style={{ width: '100%', height: '100%' }} />}
                            {!thumbnail && <img src={previewImg} style={{ width: '100%', height: '100%' }} />}
                        </Thumbnail>
                        <InputFile type='file' onChange={(e) => setThumbnail(e.target.files[0])} required />
                    </ThumbnailSection>
                    <Inputs>
                        <Input type='text' placeholder='Title' onChange={(e) => setTitle(e.target.value)} />
                        <Input type='text' placeholder='About' onChange={(e) => setAbout(e.target.value)} />
                        <TagDiv>
                            {tags && tags.map(tag => (
                                <Tag key={tag}>{tag} &nbsp; <IoIosCloseCircleOutline size={14} onClick={() => removeTag(tag)} /> </Tag>
                            ))}
                            <input type="text" placeholder="Add tags" onKeyDown={(e) => addTag(e)} style={{ width: 'auto', border: 'none', outline: 'none', fontSize: '14px', fontFamily: 'Manrope' }} />
                        </TagDiv>
                        <Select onChange={(e) => setType(e.target.value)}>
                            <option value=''>Select Type</option>
                            <option value='tech'>Tech</option>
                            <option value='dev'>Dev</option>
                            <option value='design'>Design</option>
                        </Select>
                    </Inputs>
                </ImageAndDetails>
                <TextEditor>
                    <Editor
                        form='CollectionForm'
                        apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                        onInit={(evt, editor) => editorRef.current = editor}
                        init={{
                            width: '100%',
                            height: '60rem',
                            placeholder: 'Start writing [Spell Check : Ctrl+Right click.]',
                            menubar: true,
                            statusbar: false,
                            branding: false,
                            inline_boundaries: false,
                            skin: 'fabric',
                            icons: "thin",
                            plugins: Plugins,
                            toolbar: Toolbar,
                            browser_spellcheck: true,
                            codesample_global_prismjs: true,
                            content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700&family=Exo+2:wght@300;500;600&family=Inconsolata:wght@200;300;400;500;600;700;800&family=Indie+Flower&family=Lobster+Two:wght@400;700&family=Lora:wght@400;500;600&family=Merriweather:wght@300;400;700;900&family=Mukta:wght@200;300;400;500;600;700&family=Nunito:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Oswald:wght@200;400;500;600;700&family=Playfair+Display:wght@400;500;600;700&family=Raleway:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700;900&family=Slabo+27px&family=Teko:wght@300;400;500;600&family=Titillium+Web:wght@200;300;400;600&display=swap');body {font-family:'Manrope', sans-serif; line-height: 1.5;} @media (max-width:420px){img{width: 90%; height: auto;}};",
                            fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 36pt",
                            font_formats: "Manrope, sans-serif;Dancing Script, cursive;Dosis, sans-serif;Exo 2, sans-serif;Inconsolata, monospace;Indie Flower, cursive;Lobster Two, cursive;Lora, serif;Merriweather, serif;Mukta, sans-serif;Nunito, sans-serif;Open Sans, sans-serif;Oswald, sans- serif;Playfair Display, serif;Raleway, sans-serif;Roboto, sans-serif;Slabo 27px, serif;Teko, sans-serif;Titillium Web, sans-serif;",
                        }}
                        onChange={() => setEditorData(editorRef.current.getContent())}
                    />
                    <SubmitBtn onClick={() => submitBlog()}> {loading ? <ScaleLoader color="white" height={18} /> : <>UPLOAD POST</>} </SubmitBtn>
                </TextEditor>
            </CreateBlog>
        </>
    )
}

export default Publish;