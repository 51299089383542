import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import NavMenu from "./NavMenu";
import { AiOutlineEdit } from 'react-icons/ai'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import axios from "axios";
import EditBlog from "./EditBlog";
import crossPng from '../Images/cross.png'




const H2 = styled.h2`
    margin: 8px;
    color: #46382B;
    font-size: 32px;
`

const BlogListPage = styled.div`
    font-family: 'Manrope',sans-serif;
    max-width: 1200px;
    margin: 4rem 2rem;
    padding: 12px;
`
const Uplods = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const Post = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px;
`
const Thumbnail = styled.div`
    display: flex;
    height: 160px;
    width: 256px;
    background: lightgray;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    justify-content: center;
`
const Opts = styled.div`
    display: flex;
    justify-content: center;
    height: max-content;
    bottom: 0;
    position: absolute;
    width: 100%;
    background: #00000047;
    padding: 5px;
`
const RemoveWin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #00000073;
    height: 100%;
    width: 100%;
    top: 0;
`
const RemoveWinContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: white;
    width: 28rem;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    font-family: Manrope;
    overflow: hidden;
    margin: 8px;
`
const Btns = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 28px;
    width: 100%;
`
const Buttton = styled.button`
    width: 50%;
    padding: 8px;
    font-size: 16px;
    border: none;
    color: white;
    cursor: pointer;
    margin: 4px;
    border-radius: 4px;
    width: 6rem;
`

const ConfirmWarn = styled.span`  
    font-weight: 600;
    margin-top: 24px;
    font-size: 16px;
`


function BlogList() {
    const sCode = sessionStorage.getItem('security_code')
    const [blogs, setBlogs] = useState([]);
    const [edit, setEdit] = useState(false);
    const [blogData, setBlogData] = useState([])
    const [refreshWin, setRefreshWin] = useState(Math.random())
    const [removeWin, setRemoveWin] = useState(false);
    const [blogId, setBlogId] = useState('')
    const [blogName, setBlogName] = useState('')

    useEffect(() => {
        const formdata = new FormData();
        formdata.append('sCode', sCode)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_ARTICLES, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const obj = JSON.parse(xhr.responseText);
            if (xhr.status == 200) {
                setBlogs(obj.result)
            } else {
                console.log(obj.result)
            }
        }
        xhr.send(formdata);
    }, [refreshWin])

    // close Edit Panel 
    const editBlogWindow = (data) => {
        setEdit(data)
    }
    const editBlog = (val) => {
        setEdit(true)
        setBlogData(val)
    }
    const refresh = (data) => {
        setEdit(false)
        setRefreshWin(data)
    }
    const removeBlog = (title, id) => {
        setRemoveWin(true)
        setBlogId(id)
        setBlogName(title)
    }
    // Confirm Remove 
    const confirmRemove = () => {
        axios.post(process.env.REACT_APP_API_URL + '/removeBlog', {
            id: blogId,
            sCode: sCode
        }).then(res => {
            setRemoveWin(false)
            setRefreshWin(Math.random())
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <>
            {edit && <EditBlog editBlogWindow={editBlogWindow} blogData={blogData} refresh={refresh} />}
            <NavMenu></NavMenu>
            <BlogListPage>
                <H2>Blog list</H2>
                <Uplods>
                    {blogs && blogs.map(val => (
                        <Post key={val._id}>
                            <Thumbnail>
                                <img src={val.Thumbnail} style={{ width: '100%', height: '100%' }} />
                                <Opts>
                                    <AiOutlineEdit onClick={() => editBlog(val)} size={20} style={{ padding: '4px', background: 'lightgrey', borderRadius: '4rem', cursor: 'pointer' }} />
                                    <IoIosRemoveCircleOutline size={20} style={{ padding: '4px', background: 'lightgrey', borderRadius: '4rem', marginLeft: '8px', cursor: 'pointer' }} onClick={() => removeBlog(val.Title, val._id)} />
                                </Opts>
                            </Thumbnail>
                        </Post>
                    ))}
                </Uplods>
            </BlogListPage>
            {removeWin &&
                <RemoveWin>
                    <RemoveWinContainer>
                        <img src={crossPng} style={{ width: '4rem', marginTop: '16px' }} />
                        <ConfirmWarn>Do you want to permanently remove this article  <span style={{ borderBottom: '2px solid #FE7D02' }}>{blogName}</span> ?</ConfirmWarn>
                        <Btns>
                            <Buttton onClick={() => confirmRemove()} style={{ color: 'black', background: 'none', border: '1px solid gray' }}>Remove</Buttton>
                            <Buttton style={{ background: '#FE7D02' }} onClick={() => setRemoveWin(false)}>Decline</Buttton>
                        </Btns>
                    </RemoveWinContainer>
                </RemoveWin>
            }
        </>
    )
}

export default BlogList