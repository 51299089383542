import { useState, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import { Routes, Route, NavLink, json } from "react-router-dom";
import '../App.css'
import { RiAdminLine } from 'react-icons/ri'
import { ScaleLoader } from 'react-spinners';



const LoginBox = styled.div`
  font-family: 'Manrope',sans-serif;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
`
const H2 = styled.h2`
  display: flex;
  align-items: center;
  color: #32281f;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 24rem;
  margin: 0 8px;
`
const Input = styled.input`
  display: flex;
  border: none;
  margin: 8px 0;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-family: manrope;
  border: none;
  border: 1px solid lightgray;
  border-radius: 4px;
  outline: none;
`
const Button = styled.button`
  display: flex;
  margin: 12px 0;
  padding: 10px;
  justify-content: center;
  background: #46382b;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
`

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [verification, setVerification] = useState(false)
    const [sCode, setSCode] = useState('');
    const [loading, setLoading] = useState(false)


    const verify = (e) => {
        e.preventDefault();
        setLoading(true)
        const formdata = new FormData();
        formdata.append('email', email);
        formdata.append('password', password);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_VERIFY, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            setLoading(false)
            const obj = JSON.parse(xhr.responseText)
            if (xhr.status === 200) {
                setVerification(true)
            } else {
                alert(obj.result)
            }
        }
        xhr.send(formdata);
    }


    const login = (e) => {
        e.preventDefault();
        setLoading(true)
        const formdata = new FormData();
        formdata.append('sc', sCode)
        const xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_API_URL_LOGIN, true);
        xhr.getResponseHeader('Content-type', 'application/json');
        xhr.onload = () => {
            setLoading(false)
            if (xhr.status == 200) {
                const obj = JSON.parse(xhr.responseText);
                const securityCode = obj.result;
                sessionStorage.setItem('security_code', securityCode)
                window.location.href = '/publish'
            } else {
                console.log(xhr.responseText)
            }
        }
        xhr.send(formdata);
    }

    return (
        <LoginBox>
            {!verification &&
                <Form onSubmit={(e) => verify(e)}>
                    <H2><RiAdminLine size={24} />&nbsp;Admin Verification</H2>
                    <Input type='email' placeholder='Username' onChange={(e) => setEmail(e.target.value)} />
                    <Input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                    <Button type='submit'>{loading ? <ScaleLoader color="white" height={18} /> : <>GET VERIFIED</>}</Button>
                </Form>
            }
            {verification &&
                <Form onSubmit={(e) => login(e)}>
                    <H2>Login</H2>
                    <Input type='text' placeholder='Enter security code.' onChange={(e) => setSCode(e.target.value)} />
                    <Button type='submit'>{loading ? <ScaleLoader color="white" height={18} /> : <>VERIFY & LOGIN</>}</Button>
                </Form>
            }
        </LoginBox>
    );
}

export default Login;
