import { useEffect, useState } from "react"
import axios from "axios";
import styled from "styled-components";
import NavMenu from "./NavMenu";


const StaticsPanel = styled.div`
   font-family: Manrope;
   max-width: 1200px;
   margin: 4rem 2rem;
   padding: 12px;
`
const H1 = styled.h1`
   font-family: Manrope;
   color: #46382b;
`
const Visit = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;
`
const Span = styled.span`
   display: flex;
   font-size: 14px;
   color: #46382b;
   margin: 4px 0;
`


export default function Messages() {
    const [msgData, setMsgData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axios.get(process.env.REACT_APP_API_URL + '/message')
            .then(res => {
                setLoading(false)
                setMsgData(res.data.result);
            })
    }, [])

    return (
        <>
            <NavMenu></NavMenu>
            <StaticsPanel>
                <H1>Messages</H1>
                {msgData && msgData.toReversed().map(val => (
                    <Visit key={val._id}>
                        <Span><strong>Name:</strong>&nbsp;{val.Name}</Span>
                        <Span><strong>Email:</strong>&nbsp;{val.Email}</Span>
                        <Span><strong>Subject:</strong>&nbsp;{val.Subject}</Span>
                        <Span><strong>Message:</strong>&nbsp;{val.Message}</Span>
                        <Span><strong>Date:</strong>&nbsp;{val.Date}</Span>
                    </Visit>
                ))}
                {loading && <Span><strong>Loading...</strong></Span>}
            </StaticsPanel>
        </>
    )
}