import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import Images from "./Images";
import Followers from "./Followers";
import { NavLink } from "react-router-dom";
import { GrUserAdmin } from 'react-icons/gr'
import { RiMenu2Line } from 'react-icons/ri'
import { BsDot } from 'react-icons/bs'
import { BiRightArrowAlt } from 'react-icons/bi'
import { useNavigate } from "react-router-dom";


const Brand = styled.div`
    display: flex;
    position: absolute;
    left: 2%;
    align-items: center;

`
const Nav = styled.nav`
    display: flex;
    justify-content: right;
    max-width: 80%;
    margin: 24px auto;
    font-family: Manrope;
    position: relative;

    @media (max-width: 1100px){
        max-width: 95%;
    }
  
`
const Hamburger = styled.span`
    display: none;

    @media (max-width: 700px){
        display: flex;
    }
`
const NavItem = styled.span`
    display: flex;
    margin-left: 16px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    @media (max-width: 700px){
        display: none;
    }
`
const MobNav = styled.div`
    display: flex;
    flex-direction: column;
    background: #fe7d00;
    color: white;
    width: 11rem;
    position: fixed;
    top: 0;
    right: 0;
    padding: 2rem;
    transition: 0.5s all;
    border-bottom-left-radius: 16px;
    z-index: +4;
    box-shadow: 0 0 8px #111111;
`

const MobNavItem = styled.span`
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: 14px;
    font-weight: 400;
    color: white;
`
const H3 = styled.h3`
    
`
const Close = styled.span`
    position: absolute;
    right: 7px;
    top: 8px;
`

function NavMenu() {
    const navigate = useNavigate();
    const [imgWindow, setImgWindow] = useState(false);
    const [followersWindow, setFollowersWindow] = useState(false);
    const [mobNav, setMobNav] = useState(false);

    const ImageWindow = (data) => {
        setImgWindow(data)
    }

    const FollowersWindow = (data) => {
        setFollowersWindow(data)
    }

    return (
        <>
            {imgWindow && <Images ImageWindow={ImageWindow} />}
            {followersWindow && <Followers FollowersWindow={FollowersWindow} />}
            <Nav>
                <Brand>
                    <GrUserAdmin size={32} color="#46382B" />&nbsp;&nbsp;
                    <span style={{ color: '#46382B', fontWeight: 600 }}>smDevBox | Admin</span>
                </Brand>
                <NavItem><NavLink style={({ isActive }) => isActive ? { borderBottom: '2px solid #46382b' } : { borderBottom: '2px solid white' }} to='/publish'> Publish </NavLink></NavItem>
                <NavItem><NavLink style={({ isActive }) => isActive ? { borderBottom: '2px solid #46382b' } : { borderBottom: '2px solid white' }} to='/blog_list'> Blog List</NavLink> </NavItem>
                <NavItem onClick={() => setImgWindow(true)}>Images</NavItem>
                <NavItem onClick={() => setFollowersWindow(true)}>Followers</NavItem>
                <NavItem>
                    <NavLink
                        style={({ isActive }) => isActive ? { borderBottom: '2px solid #46382b' } : { borderBottom: '2px solid white' }} to='/statics'>
                        Statics
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={({ isActive }) => isActive ? { borderBottom: '2px solid #46382b' } : { borderBottom: '2px solid white' }} to='/messages'>
                        Messages
                    </NavLink>
                </NavItem>
                <Hamburger>
                    <RiMenu2Line size={22} onClick={() => setMobNav(true)} />
                </Hamburger>
                {mobNav &&
                    <MobNav>
                        <Close>
                            <BiRightArrowAlt size={22} onClick={() => setMobNav(false)} />
                        </Close>
                        <H3>Menu</H3>
                        <MobNavItem><BsDot size={22} />
                            <NavLink onClick={() => setMobNav(false)}
                                style={({ isActive }) => isActive ? { borderBottom: '2px solid white', color: 'white' } : { borderBottom: 'none', color: 'white' }} to='/create'> Create </NavLink>
                        </MobNavItem>
                        <MobNavItem>
                            <BsDot size={22} />
                            <NavLink onClick={() => setMobNav(false)}
                                style={({ isActive }) => isActive ? { borderBottom: '2px solid white', color: 'white' } : { borderBottom: 'none', color: 'white' }} to='/blog_list'> Blog List</NavLink>
                        </MobNavItem>
                        <MobNavItem onClick={() => setImgWindow(true) + setMobNav(false)}>
                            <BsDot size={22} />Images</MobNavItem>
                        <MobNavItem onClick={() => setFollowersWindow(true) + setMobNav(false)}><BsDot size={22} />Followers</MobNavItem>
                        <MobNavItem><BsDot size={22} />
                            <NavLink onClick={() => setMobNav(false)}
                                style={({ isActive }) => isActive ? { borderBottom: '2px solid white', color: 'white' } : { borderBottom: 'none', color: 'white' }} to='/statics'> Statics </NavLink>
                        </MobNavItem>
                        <MobNavItem><BsDot size={22} />
                            <NavLink onClick={() => setMobNav(false)}
                                style={({ isActive }) => isActive ? { borderBottom: '2px solid white', color: 'white' } : { borderBottom: 'none', color: 'white' }} to='/messages'> Messages </NavLink>
                        </MobNavItem>
                    </MobNav>
                }
            </Nav >
        </>
    )
}

export default NavMenu;